<template>
  <section class="content">
    <table class="table table-hover" ref="tblruang">
      <thead>
        <tr>
          <th>NAMA RUANG</th>
          <th>CABANG</th>
        </tr>
      </thead>
      <tbody @click="handleClick"></tbody>
    </table>
    <div
      class="modal"
      tabindex="-1"
      role="dialog"
      ref="formDialog"
      data-backdrop="static"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form role="form" @submit="submitForm">
            <div class="modal-header">
              <h5 class="modal-title">{{ formTitle }}</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <p v-if="errors.length" class="alert alert-danger">
                <b>Please correct the following error(s):</b>
              </p> -->
              <div class="row">
                <div class="col-md-6 form-group">
                  <label class="control-label">Nama Ruang</label>
                  <input
                    id="nama_ruang"
                    class="form-control"
                    v-model="form.nama_ruang"
                    type="text"
                    required="required"
                    name="nama_ruang"
                  />
                </div>
                <div class="col-md-6 form-group">
                  <label class="control-label">Cabang</label>
                  <select
                    v-model="form.branch_id"
                    id="branch_id"
                    name="branch_id"
                    class="form-control"
                  >
                    <option
                      v-for="cabang in groups"
                      v-bind:key="cabang.branch_id"
                      v-bind:value="cabang.branch_id"
                    >
                      {{ cabang.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "",
  data() {
    return {
      errors: [],
      groups: [],
      method: "",
      roles: "",
      formTitle: "Tambah Ruangan",
      form: {
        id: '',
        nama_ruang: "",
        branch_id: [],
      },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    authFetch("/akademik/data_induk_ruangan/groups")
      .then((res) => {
        if (res.status === 201) {
        } else if (res.status === 400) {
        }
        return res.json();
      })
      .then((js) => {
        this.groups = js.data;
      });
  },
  methods: {
    handleClick(e) {
      if (e.target.matches(".link-role")) {
        this.$router.push({ path: "/permit/" + e.target.dataset.id });
        return false;
      }
    },
    submitForm: function (ev) {
      const e = this.$refs;

      var data = Object.keys(this.form)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
        )
        .join("&");
      var urlSubmit = "/akademik/data_induk_ruangan";
      if (this.method == "PUT")
        urlSubmit = "/akademik/data_induk_ruangan/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: data,
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
  },
  mounted() {
    const e = this.$refs;
    let self = this;
    this.table = createTable(e.tblruang, {
      title: "",
      roles: this.$route.params.roles,
      ajax: "/akademik/data_induk_ruangan",
      columns: [{ data: "nama_ruang" }, { data: "cabang" }],
      filterBy: [0, 1],
      rowCallback: function (row, data) {},
      buttonClick: (evt) => {
        if (evt.role == "create") {
          self.form = {};
          self.method = "POST";
          self.errors = [];
          self.formTitle = "Tambah Ruang";
          $(e.formDialog).modal("show");
        } else if (evt.role == "update" && evt.data) {
          self.form = evt.data;
          self.method = "PUT";
          self.errors = [];
          self.formTitle = "Edit Ruang";
          $(e.formDialog).modal("show");
        } else if (evt.role == "delete" && evt.data) {
          // self.form = evt.data;
          console.log(evt);
          Swal.fire({
            title: "Hapus data?",
            icon: "question",
            denyButtonText: '<i class="fa fa-times"></i> Hapus',
            showCancelButton: true,
            showDenyButton: true,
            showConfirmButton: false,
          }).then((result) => {
            if (result.isDenied) {
              authFetch("/akademik/data_induk_ruangan/" + evt.data.id, {
                method: "DELETE",
                body: "id=" + evt.data.id,
              })
                .then((res) => {
                  return res.json();
                })
                .then((js) => {
                  this.table.api().ajax.reload();
                });
            }
          });
        }
      },
    });
  },
};
</script>
